<template>
  <div class="Logout">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 mt-2 mt-lg-0">

          <div class="gavc-card gavc-text-center gavc-padding-large">
            <div class="gavc-card__header">
              <h3 class="gavc-h3 gavc-margin-bottom"> Déconnexion </h3>
              <p class="gavc-question gavc-margin-bottom">
                Vous êtes sur le point de vous déconnecter, à bientôt  🙂
              </p>
           </div>
            <div class="gavc-card__content">
              <button
                class="gavc-btn"
                @click="logout"
                >
                Se déconnecter
              </button>
           </div>
              <div class="gavc-background-card-text">
              <p class="gavc-background-card-text__content"> déconnexion </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  methods: {
   logout: function () {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/se-connecter')
      })
    }
  }
}
</script>
